const certificatesSections = document.getElementsByClassName('js-certificates')
const appLastElementChild = document.getElementById('app').lastElementChild
const init = () => {
  if (certificatesSections.length) {
    const classJs = appLastElementChild.classList.contains('js-certificates')
    if (classJs) {
      const contactCtaSection = document.querySelector('section.contact-cta')
      contactCtaSection.classList.add('offset-mod')
    }
  }
}

export default {
  init
}
