const button = document.querySelector('.social__item--share')

const copuLink = () => {
  if (button) {
    button.onclick = (e) => {
      e.preventDefault()
      navigator.clipboard.writeText(window.location.href)
    }
  }
}

const init = () => {
  copuLink()
}

export default {
  init
}
