const init = () => {
  const modals = document.querySelectorAll('[data-modal]')

  modals.forEach(function (trigger) {
    trigger.addEventListener('click', function (event) {
      event.preventDefault()
      const modal = document.getElementById(trigger.dataset.modal)
      modal.classList.add('open')
      const exits = modal.querySelectorAll('.close-modal')
      exits.forEach(function (exit) {
        exit.addEventListener('click', function (event) {
          event.preventDefault()
          modal.classList.remove('open')
        })
      })
    })
  })
}

export default {
  init
}