const init = () => {
  const heading = document.querySelectorAll('.simple-page__content h3')
  const list = document.querySelector('.anchor')

  heading.forEach((element, index) => {
    element.setAttribute('data-target', 'item-' + index + '')
    const newHeading = element
    const clone = newHeading.cloneNode(true)

    list.appendChild(clone)
    element.setAttribute('id', 'item-' + index + '')
    element.removeAttribute('data-target')
  })

  document.addEventListener('click', function(event) {
    if (!event.target.matches('.anchor h3')) return

    event.preventDefault()

    let element = document.getElementById(event.target.dataset.target)

    element.scrollIntoView({behavior: 'smooth'})

  })
}

export default {
  init
}