// component for make iframes adaptive in the Redactor fields

import reframe from 'reframe.js'

const init = () => {
  reframe('.text iframe')
}

export default {
  init
}
