import Components from './components'
import * as Sentry from '@sentry/browser'

import '../css/main.scss'

Sentry.init({
  dsn: 'https://c1c1d39952344c7caf71e5d97515bf8f@sentry.yournext.agency/81'
})

if (Components.length) {
  Components.forEach((component) => component.init())
}