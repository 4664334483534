import Reframe from './reframe'
// import Forms from './forms/refreshCsrfFields'
import Faq from './faq'
import Header from './header'
import Video from './video'
import Scroll from './scroll'
import Modal from './modal'
import SimplePageList from './simplePageList'
import CompleteMessage from './complete'
import Mask from './mask'
import LoadMore from './loadMore'
import copyLink from './copyLink'
import Certificates from './certificates'

export default [
  Reframe,
  // Forms,
  Faq,
  Header,
  Video,
  Scroll,
  Modal,
  SimplePageList,
  CompleteMessage,
  Mask,
  LoadMore,
  copyLink,
  Certificates
]