let completeMessage
let form

const openCompleteMessage = () => {
  completeMessage.classList.add('complete-message_show')
}

const events = async () => {
  document.addEventListener('onAfterFormieSubmit', openCompleteMessage)
}

const init = () => {
  form =  document.querySelector('form')
  completeMessage = document.querySelector('.complete-message')

  if (completeMessage && form) {
    events()
  }
}

export default {
  init
}
