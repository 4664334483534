import Plyr from 'plyr'

const init = () => {

  // const player = new Plyr('.js-player', {})
  const mytap = (window.ontouchstart === null) ? 'touchstart' : 'click'

  const player = Array.from(document.querySelectorAll('.js-player'))
      .map((p) => new Plyr(p))

  /* if enabled autoplay on modal open
      const openModal = document.querySelectorAll('.learn-item')
      for (let i = 0; i < openModal.length; i++) {
          openModal[i].addEventListener('click', () => {
            // player[i].play()
            console.log('player' + i)
          })
      }
    */

  // const closeModalOverlay = document.querySelectorAll('.modal__overlay_in')
  // for (let i = 0; i < closeModalOverlay.length; i++) {
  //
  //   closeModalOverlay[i].addEventListener(mytap, () => {
  //     player[i].pause()
  //     console.log('pause' + i)
  //   })
  //
  // }
  const btnPlay = document.querySelectorAll('.js-video-btn')
  const previewImage = document.querySelectorAll('.article__video-img')
  for (let i = 0; i < btnPlay.length; i++) {

    btnPlay[i].addEventListener(mytap, (e) => {
      e.preventDefault()
      previewImage[i].classList.add('hide')
      player[i].play()
      /*closeModalOverlay.click()*/
      console.log('play' + i)
    })

  }

}

export default {
  init
}
