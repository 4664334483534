let index

const links = document.querySelectorAll('.js-scroll')

const events = () => {
  // Add event listener to all links
  links.forEach(function (elem) {
    elem.addEventListener('click', smoothScroll)
  })

  function smoothScroll(e) {
    e.preventDefault()
    const link = this.getAttribute('href')

    const offsetTop = document.querySelector(link).offsetTop

    scroll({
      top: offsetTop,
      behavior: 'smooth'
    })
  }
}

const init = () => {
  index = links

  if (index) {
    events()
  }
}

export default {
  init
}
