class Component {
  constructor() {
    this.getEntries = this.getEntries.bind(this)
  }

  initState() {
    this.list = document.querySelector('.blog__inner')
    this.container = document.querySelector('.blog__load-more')
    this.btn = document.querySelector('.blog__more-btn')
    this.currentPage = 1
    this.total = this.container?.dataset.total
    this.limit = this.container?.dataset.limit
    this.allowLoad = this.currentPage < this.total
    this.isMobile = window.matchMedia('(max-width: 767px)').matches

    this.total = this.total / this.limit
  }

  getEntries() {
    if (!this.allowLoad) {
      return
    }

    this.btn.classList.add('hidden')

    fetch('/ajax/blogLoadMore/posts?' + new URLSearchParams({
      limit: this.limit,
      currentPage: this.currentPage
    }), {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
        .then(response => response.text())
        .then(response => {
          this.btn.classList.remove('hidden')
          this.list.insertAdjacentHTML('beforeend', response)

          this.items = document.querySelectorAll('.animate')

          Array.from(this.items).forEach(item => this.observer.observe(item))

          if (this.currentPage < this.total) {
            this.currentPage++
          }

          if (this.currentPage >= this.total) {
            this.container.classList.add('hidden')
            this.allowLoad = false
          }
        }).catch(function () {
      this.btn.classList.remove('hidden')
      this.pending.classList.add('hidden')
      console.error('pagination error')
    })
  }

  init() {
    this.initState()

    if (!this.list || !this.btn) {
      return
    }

    this.btn.addEventListener('click', this.getEntries)

    if (this.isMobile) {
      return
    }
  }

  destroy() {
    if (this.btn) {
      this.btn.removeEventListener('click', this.getEntries)
    }
  }
}

export default new Component()
