let index
const menuBtn = document.querySelectorAll('.mobile-menu')
const menuWrapper = document.querySelectorAll('.navigation__wrapper')
const bodyWrapper = document.querySelector('body')
const headerWrapper = document.querySelector('.header')
const svgTitles = ['.page-hero__title', '.cta__text', '.team__title', '.services-section .title-3']

const toggleMobileMenu = () => {
  menuBtn.forEach((el) => el.classList.toggle('open'))
  menuBtn.forEach((el) => el.classList.toggle('mobile-menu__open'))
  menuWrapper.forEach((el) => el.classList.toggle('open'))
  bodyWrapper.classList.toggle('hidden')
  headerWrapper.classList.toggle('mobile-menu__wrapper')

  const dropdowns = [...document.querySelectorAll('.has-dropdown')]
  if (dropdowns) {
    dropdowns.forEach((el) => el.classList.remove('active'))
  }
}

const events = () => {
  menuBtn.forEach((el) => el.addEventListener('click', toggleMobileMenu))

  const dropdownHover = document.querySelector('.has-dropdown')
  if (window.innerWidth < 991) {
    dropdownHover.addEventListener('click', function (){
      this.classList.toggle('open-dropDown')
      headerWrapper.classList.toggle('open-menu')
    })
  }
  if (window.innerWidth > 991) {
    dropdownHover.addEventListener('mouseover', function (){
      this.classList.add('open-dropDown')
      headerWrapper.classList.add('open-menu')
    })

    dropdownHover.addEventListener('mouseout', function (){
      this.classList.remove('open-dropDown')
      headerWrapper.classList.remove('open-menu')
    })
  }
}

const appendStripe = (el) => {
  if (el) {
    const svgStripe = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    let newPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    const lineWidth = window.innerWidth < 768 ? el.offsetWidth - 5 : (el.offsetWidth - 10)
    const lineCurve = lineWidth / 100 * 5
    // console.log(el.getBoundingClientRect())

    newPath.setAttributeNS(null, 'd', `M 5 ${lineCurve} C ${lineCurve} ${lineCurve} ${lineWidth / 2} 0 ${lineWidth} ${lineCurve} H ${lineWidth}`)
    newPath.setAttributeNS(null, 'stroke', '#FB7800')
    newPath.setAttributeNS(null, 'stroke-linecap', 'round')
    newPath.setAttributeNS(null, 'stroke-width', 4)
    newPath.setAttributeNS(null, 'opacity', 1)
    newPath.setAttributeNS(null, 'fill', 'none')
    el.append(svgStripe)
    // svgStripe.innerHTML = `<path d="M 25 25 C 25 25 ${lineWidth / 2} 0 ${lineWidth} 25 H ${lineWidth}" stroke="#FB7800" stroke-linecap="round" fill="none" stroke-width="5" />`
    svgStripe.appendChild(newPath)
    // console.log(svgStripe, lineWidth)
  }
}

const init = () => {
  index = document.querySelector('.header')
  const navigation = [...document.querySelectorAll('.navigation')]

  if (navigation) {
    const drops = navigation.map((el) => el.querySelectorAll('.dropdown'))
    drops.forEach((elem) => elem.forEach(
        (el) => el.parentElement.classList.add('has-dropdown')
    ))
  }

  setTimeout(function () {
    if (svgTitles) {
      svgTitles.forEach((item) => {
        const targetEl = document.querySelector(item)
        if (targetEl) {
          appendStripe(targetEl.querySelector('strong'))
        }
      })
    }
  }, 100)

  if (index) {
    events()
  }
}

export default {
  init
}
