const faqList = document.querySelector('.faq')

const toggleFaqItem = (e) => {
  const currentDt = e.target

  if (currentDt.classList.contains('state-active')) {
    currentDt.classList.remove('state-active')
  } else {
    currentDt.classList.add('state-active')
  }
}

const init = () => {
  if (faqList) {
    faqList.querySelectorAll('dt').forEach((item) => {
      item.addEventListener('click', toggleFaqItem)
    })
  }
}

export default {
  init
}
